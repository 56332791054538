import { ReactElement, KeyboardEvent, MouseEventHandler, CSSProperties } from "react";
import {
  Controller,
  Control,
  FieldValues,
  ControllerRenderProps,
  ControllerFieldState,
  UseFormStateReturn,
  RegisterOptions,
} from "react-hook-form";
import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Select,
  FilledInputProps,
  InputProps,
  OutlinedInputProps,
  MenuItem,
  SxProps,
  Theme,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateValidationError, DateView, LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { forwardRef } from "react";
import NumberFormat from "react-number-format";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { PickerChangeHandlerContext } from "@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types";
import { Dayjs } from "dayjs";

interface renderProp {
  field: ControllerRenderProps<FieldValues, string>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
}

const controllerComponent = (
  inputName: string,
  control: Control<FieldValues, any>,
  defaultValue: any,
  func: ({ field }: renderProp) => ReactElement,
  rules?:
    | Omit<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">
    | undefined
) => {
  return (
    <Controller
      control={control}
      name={inputName}
      render={func}
      defaultValue={defaultValue}
      rules={rules}
    />
  );
};

const errorMessageDsplay = (text: string) => (
  <p
    style={{
      color: "red",
      fontSize: 12,
      marginTop: 5,
      marginLeft: 5,
      marginBottom: 0,
      fontWeight: "bold",
    }}
  >
    {text}
  </p>
);

type OutlineInputProps = {
  control: Control<FieldValues, any>;
  inputName: string;
  inputRef?:
    | ((instance: HTMLDivElement | null) => void)
    | React.RefObject<HTMLDivElement>
    | null
    | undefined;
  label: string;
  rules?: object;
  errors?: boolean;
  defaultValue?: any;
  inputType?: string;
  onKeyDown?: (e: KeyboardEvent) => void;
  size?: "small" | "medium" | undefined;
  placeholder?: string;
  disabled?: boolean;
  multiline?: boolean;
  minRows?: number;
  autoFocus?: boolean;
  focused?: boolean;
  inputStyle?: object;
  inputContainerStyle?: CSSProperties;
  inputProps?:
    | Partial<InputProps>
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | undefined;
  helperText?: string;
};

export const OutlineInput = ({
  control,
  inputName,
  inputRef,
  label,
  errors,
  rules,
  defaultValue = "",
  inputType = "text",
  onKeyDown,
  size = "medium",
  placeholder,
  multiline = false,
  minRows = 1,
  disabled = false,
  autoFocus,
  focused,
  inputStyle,
  inputContainerStyle = { marginBottom: 12 },
  inputProps,
  helperText,
}: OutlineInputProps) =>
  controllerComponent(
    inputName,
    control,
    defaultValue,
    ({ field, fieldState: { error } }) => (
      <div style={inputContainerStyle}>
        <TextField
          {...field}
          ref={inputRef}
          focused={focused}
          size={size}
          placeholder={placeholder}
          error={errors}
          id={inputName}
          label={label}
          fullWidth
          type={inputType}
          autoFocus={autoFocus}
          sx={inputStyle}
          disabled={disabled}
          multiline={multiline}
          minRows={minRows}
          onKeyDown={onKeyDown}
          InputProps={inputProps}
          helperText={helperText}
        />
        {error !== undefined && errorMessageDsplay(error.message!)}
      </div>
    ),
    rules
  );

type OutlineInputPassProps = {
  control: Control<FieldValues, any>;
  inputName: string;
  label: string;
  showPass: boolean;
  errors: boolean;
  onTap: () => void;
  rules: object;
  size?: "small" | "medium" | undefined;
  disabled?: boolean;
  placeholder?: string;
  inputStyle?: object;
  inputContainerStyle?: CSSProperties;
};

export const OutlineInputPassword = ({
  control,
  inputName,
  label,
  showPass,
  errors,
  onTap,
  rules,
  size = "medium",
  disabled = false,
  placeholder,
  inputStyle,
  inputContainerStyle = { marginBottom: 10 },
}: OutlineInputPassProps) =>
  controllerComponent(
    inputName,
    control,
    "",
    ({ field, fieldState: { error } }) => (
      <div style={inputContainerStyle}>
        <FormControl
          error={errors}
          variant="outlined"
          fullWidth
          sx={inputStyle}
          size={size}
          disabled={disabled}
        >
          <InputLabel htmlFor={inputName}>{label}</InputLabel>
          <OutlinedInput
            {...field}
            id={inputName}
            type={showPass ? "text" : "password"}
            placeholder={placeholder}
            autoComplete="off"
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={onTap} edge="end" disabled={disabled}>
                  {showPass ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label={label}
          />
        </FormControl>
        {error !== undefined && errorMessageDsplay(error.message!)}
      </div>
    ),
    rules
  );

type OutlineSelectInputProps = {
  control: Control<FieldValues, any>;
  inputName: string;
  label: string;
  displayValue: false | JSX.Element[];
  displayMessage: string;
  errors: boolean;
  rules: object;
  onTap?: MouseEventHandler<HTMLDivElement> | undefined;
  size?: "small" | "medium" | undefined;
  disabled?: boolean;
  placeholder?: string;
  inputStyle?: object;
  inputContainerStyle?: CSSProperties;
};

export const OutlineSelectInput = ({
  control,
  inputName,
  label,
  displayValue,
  displayMessage,
  errors,
  rules,
  onTap,
  size = "medium",
  disabled = false,
  inputStyle = { mb: 0 },
  inputContainerStyle = { marginBottom: 10 },
}: OutlineSelectInputProps) => {
  return (
    <>
      {controllerComponent(
        inputName,
        control,
        "",
        ({ field, fieldState: { error } }) => (
          <div style={inputContainerStyle}>
            <FormControl fullWidth sx={inputStyle} error={errors} disabled={disabled} size={size}>
              <InputLabel id={inputName}>{label}</InputLabel>
              <Select {...field} labelId={label} id={inputName} label={label} onClick={onTap}>
                <MenuItem value="" disabled>
                  {displayMessage}
                </MenuItem>
                {displayValue}
              </Select>
            </FormControl>
            {error !== undefined && errorMessageDsplay(error.message!)}
          </div>
        ),
        rules
      )}
    </>
  );
};

interface NumberProps {
  onChange: (values: number) => void;
  name: string;
}

export const NumberFormatCustom = forwardRef<any, NumberProps>(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => onChange(values.floatValue === undefined ? 0 : values.floatValue)}
      thousandSeparator
      isNumericString
      allowNegative={false}
      prefix="Rp "
    />
  );
});

type DateInputProp = {
  label: string;
  control: Control<FieldValues, any>;
  inputName: string;
  inputValue?: any;
  isDisabled: boolean;
  onChange?: (
    value: Dayjs | null,
    context: PickerChangeHandlerContext<DateValidationError>
  ) => void;
  minDate?: any;
  maxDate?: any;
  views?: readonly DateView[] | undefined;
  sx?: SxProps<Theme> | undefined;
  readOnly?: boolean;
  size?: "small" | "medium";
  rules?:
    | Omit<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">
    | undefined;
};

export const DateInput = ({
  label,
  control,
  inputName,
  inputValue,
  isDisabled = false,
  onChange,
  views,
  maxDate,
  minDate,
  sx,
  size = "small",
  readOnly = false,
  rules,
}: DateInputProp) => {
  return controllerComponent(
    inputName,
    control,
    inputValue,
    ({ field, fieldState: { error } }) => {
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            {...field}
            views={views}
            label={label}
            onChange={onChange}
            disabled={isDisabled}
            maxDate={maxDate}
            readOnly={readOnly}
            minDate={minDate}
            sx={sx}
            slotProps={{
              textField: { size, error: error !== undefined, helperText: error?.message },
            }}
          />
        </LocalizationProvider>
      );
    },
    rules
  );
};
