import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface state {
  listBarcodes: any[];
  isLoading: boolean;
}

const initialState: state = {
  listBarcodes: [],
  isLoading: true,
};

const slice = createSlice({
  name: "barcodes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllBarcode.fulfilled, (state, { payload }) => {
        const { data } = payload;
        return {
          ...state,
          isLoading: false,
          listBarcodes: data,
        };
      })
      .addCase(getAllBarcode.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
// export const {} = slice.actions;
export default slice.reducer;

export const getAllBarcode = createAsyncThunk("getAllBarcode", async (id: string) => {
  try {
    const { data } = await axiosInstance.get(`/barcode?credential=${id}`);
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
});
