import authReducer from "./auth"; // auth

import dashboardReducer from "./slices/dashboard";
import announceReducer from "./slices/announcement";
import rolesReducer from "./slices/roles";
import employeesReducer from "./slices/employees";
import visitorHistory from "./slices/visitor-history";
import permissionsReducer from "./slices/permissions";
import securityReducer from "./slices/security";
import visitors from "./slices/visitors";
import printBarcode from "./slices/print-barcode";
import scanVisitor from "./slices/scan-visitor";
import password from "./slices/password";

export const reducer = {
  auth: authReducer,
  dashboard: dashboardReducer,

  announcement: announceReducer,

  roles: rolesReducer,
  employees: employeesReducer,
  security: securityReducer,
  permissions: permissionsReducer,

  visitorHistory,
  visitors,
  printBarcode,
  scanVisitor,
  password,
};
