import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface State {
  isDisabled: boolean;
  isShowPrevPass: boolean;
  isShowPassword: boolean;
  isShowConfPassword: boolean;
}

const initialState: State = {
  isDisabled: false,
  isShowPrevPass: false,
  isShowPassword: false,
  isShowConfPassword: false,
};

const slices = createSlice({
  name: "password",
  initialState,
  reducers: {
    handleShowPass: (state, { payload }) => {
      if (payload === "password") {
        state.isShowPassword = !state.isShowPassword;
      } else if (payload === "confPassword") {
        state.isShowConfPassword = !state.isShowConfPassword;
      } else {
        state.isShowPrevPass = !state.isShowPrevPass;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state) => {
        state.isDisabled = true;
        state.isShowPassword = false;
        state.isShowConfPassword = false;
        state.isShowPrevPass = false;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.isDisabled = false;
      })
      .addCase(changePassword.rejected, (state) => {
        state.isDisabled = false;
      });
  },
});
export const { handleShowPass } = slices.actions;
export default slices.reducer;

export const changePassword = createAsyncThunk("changePassword", async (data: any) => {
  try {
    const response = await axiosInstance.put(`/reset-password`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});
