import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface state {
  listSecurity: any[];
  listKomplek: any[];
  editMode: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  showModal: boolean;
  id: string;
  editValue: {};
  isShowPassword: boolean;
  isShowConfPassword: boolean;
  image: {
    imageDisplay: any;
    imageName: any;
    imageValue: any;
  };
  count: number;
  currentPage: number;
  totalItem: number;
}

const initialState: state = {
  listSecurity: [],
  listKomplek: [],
  editMode: false,
  isLoading: true,
  isDisabled: false,
  showModal: false,
  id: "",
  editValue: {},
  isShowPassword: false,
  isShowConfPassword: false,
  image: {
    imageDisplay: null,
    imageName: null,
    imageValue: null,
  },
  count: 1,
  currentPage: 1,
  totalItem: 20,
};

const slice = createSlice({
  name: "security",
  initialState,
  reducers: {
    showPopupModal: (state) => {
      state.showModal = !state.showModal;
      state.id = "";
      state.editMode = false;
      state.editValue = {};
      state.image = {
        imageDisplay: null,
        imageName: null,
        imageValue: null,
      };
    },
    imageHandler: (state, { payload }) => {
      state.image = {
        imageDisplay: URL.createObjectURL(payload),
        imageName: payload.name,
        imageValue: payload,
      };
    },
    updateSecurity: (state, { payload }) => {
      const [findData] = state.listSecurity.filter((data: any) => data.id === payload);

      if (findData !== undefined) {
        state.showModal = !state.showModal;
        state.id = findData.id;
        state.editMode = true;
        state.editValue = findData;
        state.image = {
          imageDisplay: process.env.REACT_APP_CLIENT_URL + findData.ktp,
          imageName: findData.ktp,
          imageValue: findData.ktp,
        };
      }
    },
    handleChange: (state, { payload }) => {
      state.currentPage = payload;
    },
    handleShowPass: (state, { payload }) => {
      if (payload === "password") {
        state.isShowPassword = !state.isShowPassword;
      }

      if (payload === "confPassword") {
        state.isShowConfPassword = !state.isShowConfPassword;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSecurity.fulfilled, (state, action) => {
        const { data, count } = action.payload;
        return {
          ...state,
          isLoading: false,
          listSecurity: data,
          // listKomplek: komplek,
          count,
        };
      })
      .addCase(getAllSecurity.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createSecurity.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(createSecurity.fulfilled, (state) => {
        state.isDisabled = false;
      })
      .addCase(createSecurity.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(editSecurity.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(editSecurity.fulfilled, (state, { payload }) => {
        const { data } = payload;
        const index = state.listSecurity.findIndex((prevData: any) => prevData.id === data.id);
        state.isDisabled = false;
        state.listSecurity[index] = data;
      })
      .addCase(editSecurity.rejected, (state) => {
        state.isDisabled = false;
      });
  },
});
export const { showPopupModal, handleShowPass, updateSecurity, handleChange, imageHandler } =
  slice.actions;
export default slice.reducer;

export const getAllSecurity = createAsyncThunk(
  "getAllSecurity",
  async (data: { totalItem: number; currentPage: number }) => {
    try {
      const response = await axiosInstance.get(
        `/security?totalItem=${data.totalItem}&page=${data.currentPage}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const createSecurity = createAsyncThunk("createSecurity", async (data: any) => {
  try {
    const response = await axiosInstance.post(`/security`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const editSecurity = createAsyncThunk("editSecurity", async (data: any) => {
  try {
    const response = await axiosInstance.put(`/security`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const deleteSecurity = createAsyncThunk("deleteSecurity", async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/security/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const resetSecurity = createAsyncThunk("resetSecurity", async (id: string, _) => {
  try {
    const response = await axiosInstance.put(`/security-reset/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});
