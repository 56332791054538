import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface state {
  listVisitor: any[];
  editMode: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  showModal: boolean;
  id: string;
  editValue: {};
  hasMore: boolean;
  currentPage: number;
  totalItem: number;
}

const initialState: state = {
  listVisitor: [],
  editMode: false,
  isLoading: true,
  isDisabled: false,
  showModal: false,
  id: "",
  editValue: {},
  hasMore: true,
  currentPage: 0,
  totalItem: 30,
};

const slice = createSlice({
  name: "visitors",
  initialState,
  reducers: {
    showPopupModal: (state) => {
      state.showModal = !state.showModal;
      state.id = "";
      state.editMode = false;
      state.editValue = {};
    },
    updatedVisitor: (state, { payload }) => {
      const [findRole] = state.listVisitor.filter((data: any) => data.id === payload);
      if (findRole !== undefined) {
        state.showModal = !state.showModal;
        state.id = findRole.id;
        state.editMode = true;
        state.editValue = findRole;
      }
    },
    cleanCache: (state) => {
      state.currentPage = 0;
      state.listVisitor = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllVisitor.pending, (state) => {
        state.isLoading = state.currentPage > 1 ? false : true;
      })
      .addCase(getAllVisitor.fulfilled, (state, { payload }) => {
        const data = payload.data;
        state.isLoading = false;

        if (data.length < state.totalItem) {
          state.hasMore = false;
        }

        if (data.length === state.totalItem) {
          state.currentPage += 1;
        }

        state.listVisitor = data;
      })
      .addCase(getAllVisitor.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(refetchVisitor.pending, (state) => {
        state.isLoading = state.currentPage > 1 ? false : true;
      })
      .addCase(refetchVisitor.fulfilled, (state, { payload }) => {
        const data = payload.data;
        state.isLoading = false;

        if (data.length < state.totalItem) {
          state.hasMore = false;
        }

        if (data.length === state.totalItem) {
          state.currentPage += 1;
        }

        state.listVisitor = [...state.listVisitor, data];
      })
      .addCase(refetchVisitor.rejected, (state) => {
        state.isLoading = false;
        state.hasMore = false;
      })
      .addCase(createVisitor.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(createVisitor.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.isDisabled = false;
        state.listVisitor = [data, ...state.listVisitor];
      })
      .addCase(createVisitor.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(editVisitor.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(editVisitor.fulfilled, (state, { meta: { arg } }) => {
        const { name, id } = arg;
        const index = state.listVisitor.findIndex((data: any) => data.id === id);
        const newValue = { id, name };
        state.isDisabled = false;
        state.listVisitor[index] = newValue;
      })
      .addCase(editVisitor.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(deleteVisitor.fulfilled, (state, { meta }) => {
        const { arg } = meta;
        const newData = state.listVisitor.filter((data: any) => data.id !== arg);
        state.listVisitor = newData;
      });
  },
});
export const { showPopupModal, updatedVisitor, cleanCache } = slice.actions;
export default slice.reducer;

export const getAllVisitor = createAsyncThunk(
  "getAllVisitor",
  async (params: { totalItem: number; currentPage: number }) => {
    try {
      const response = await axiosInstance.get(
        `/visitor?totalItem=${params.totalItem}&page=${params.currentPage}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const refetchVisitor = createAsyncThunk(
  "refetchVisitor",
  async (params: { totalItem: number; currentPage: number }) => {
    try {
      const response = await axiosInstance.get(
        `/visitor?totalItem=${params.totalItem}&page=${params.currentPage}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const createVisitor = createAsyncThunk("createVisitor", async (data: any) => {
  try {
    const response = await axiosInstance.post(`/visitor`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const editVisitor = createAsyncThunk("editVisitor", async (data: any) => {
  try {
    const response = await axiosInstance.put(`/visitor`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const deleteVisitor = createAsyncThunk("deleteVisitor", async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/visitor?credential=${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});
