import loadable from "@loadable/component";

import { routePath } from "src/layouts/SidebarLayout/Sidebar/SidebarMenu/items";
import SidebarLayout from "src/layouts/SidebarLayout";

const Dashboard = loadable(() => import("src/content/dashboard"));
const Announcement = loadable(() => import("src/content/announcement"));

const Visitor = loadable(() => import("src/content/management-visitors/visitor"));
const ScanVisitor = loadable(() => import("src/content/management-visitors/scan-visitor"));
const VisitorHistory = loadable(() => import("src/content/management-visitors/history-visitor"));

const Roles = loadable(() => import("src/content/management-team/roles"));
const Employees = loadable(() => import("src/content/management-team/employees"));
const Permissions = loadable(() => import("src/content/management-team/permissions"));
const RolePermissions = loadable(
  () => import("src/content/management-team/permissions/role-permission")
);
const Security = loadable(() => import("src/content/management-team/security"));

const ChangePassword = loadable(() => import("src/content/settings/change-password"));

export const QrPreview = loadable(() => import("src/content/management-visitors/QrPreview"));
export const PDFPreview = loadable(
  () => import("src/content/management-visitors/history-visitor/PreviewPDF")
);

const routes = {
  path: "/",
  element: <SidebarLayout />,
  children: [
    {
      id: "allow",
      path: routePath.DASHBOARD.pathName,
      element: <Dashboard />,
    },
    // Announcement
    {
      id: routePath.ANNOUNCEMENT.id,
      path: routePath.ANNOUNCEMENT.pathName,
      element: <Announcement />,
    },
    // management team
    {
      id: routePath.ROLES.id,
      path: routePath.ROLES.pathName,
      element: <Roles />,
    },
    {
      id: routePath.EMPLOYEES.id,
      path: routePath.EMPLOYEES.pathName,
      element: <Employees />,
    },
    {
      id: routePath.PERMISSION.id,
      path: routePath.PERMISSION.pathName,
      element: <Permissions />,
    },
    {
      id: routePath.ROLE_PERMISSION.id,
      path: routePath.ROLE_PERMISSION.pathName,
      element: <RolePermissions />,
    },
    {
      id: routePath.SECURITY.id,
      path: routePath.SECURITY.pathName,
      element: <Security />,
    },
    // management visitor
    {
      id: routePath.VISITOR.id,
      path: routePath.VISITOR.pathName,
      element: <Visitor />,
    },
    {
      id: routePath.HISTORY_VISITOR.id,
      path: routePath.HISTORY_VISITOR.pathName,
      element: <VisitorHistory />,
    },
    // scan visitor
    {
      id: routePath.SCAN_VISITOR.id,
      path: routePath.SCAN_VISITOR.pathName,
      element: <ScanVisitor />,
    },
    // management settings
    {
      id: "allow",
      path: routePath.CHANGE_PASSWORD.pathName,
      element: <ChangePassword />,
    },
  ],
};

export default routes;
