import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";
import { saveAs } from "file-saver";
import dayjs, { Dayjs } from "dayjs";

interface State {
  listHistory: any[];
  pdfList: any[];
  image: string;
  isDisabled: boolean;
  isPdfLoading: boolean;
  isLoading: boolean;
  date: {
    start: Dayjs;
    end: Dayjs | null;
  };
  search: {
    start: Dayjs;
    end: Dayjs | null;
  };
  page: number;
  totalItem: number;
  hasMore: boolean;
  totalGenerate: number;
  totalScanned: number;
}

const initialState: State = {
  listHistory: [],
  pdfList: [],
  isDisabled: false,
  isPdfLoading: true,
  isLoading: true,
  image: "",
  date: {
    start: dayjs(),
    end: null,
  },
  search: {
    start: dayjs(),
    end: null,
  },
  page: 0,
  totalItem: 30,
  hasMore: false,
  totalGenerate: 0,
  totalScanned: 0,
};

const slice = createSlice({
  name: "visitor history",
  initialState,
  reducers: {
    selectDate: (state, { payload }: PayloadAction<{ type: string; date: Dayjs }>) => {
      const { date, type } = payload;
      if (type === "start") {
        state.date = {
          start: date,
          end: null,
        };
      } else {
        state.date.end = date;
      }
    },
    resetData: (state) => {
      state.date = {
        start: dayjs(),
        end: null,
      };
      state.page = 0;
      state.listHistory = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchHistory.pending, (state) => {
        state.isDisabled = true;
        state.isLoading = true;
        state.page = 0;
        state.hasMore = true;
        state.listHistory = [];
        state.search = {
          start: state.date.start,
          end: state.date.end,
        };
      })
      .addCase(searchHistory.fulfilled, (state, { payload }) => {
        const { data, totalGenerate, totalScanned } = payload;
        state.isLoading = false;
        state.isDisabled = false;
        state.totalGenerate = totalGenerate;
        state.totalScanned = totalScanned;

        if (data.length < state.totalItem) {
          state.hasMore = false;
        }

        if (data.length === state.totalItem) {
          state.page += 1;
        }

        state.listHistory = data;
      })
      .addCase(searchHistory.rejected, (state) => {
        state.isLoading = false;
        state.hasMore = false;
        state.isDisabled = false;
      })
      .addCase(refetchHistory.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.isLoading = false;

        if (data.length < state.totalItem) {
          state.hasMore = false;
        }

        if (data.length === state.totalItem) {
          state.page += 1;
        }

        state.listHistory = [...state.listHistory, ...data];
      })
      .addCase(refetchHistory.rejected, (state) => {
        state.isLoading = false;
        state.hasMore = false;
      })
      .addCase(getVisitorPDF.pending, (state) => {
        state.isPdfLoading = true;
      })
      .addCase(getVisitorPDF.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.isPdfLoading = false;
        state.pdfList = data;
      })
      .addCase(getVisitorPDF.rejected, (state) => {
        state.isPdfLoading = true;
      });
  },
});
export const { selectDate, resetData } = slice.actions;
export default slice.reducer;

export const searchHistory = createAsyncThunk("searchHistory", async (params: string) => {
  try {
    const response = await axiosInstance.get(`/visitor-history?${params}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const refetchHistory = createAsyncThunk("refetchHistory", async (params: string) => {
  try {
    const response = await axiosInstance.get(`/visitor-history?${params}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const getVisitorPDF = createAsyncThunk("getVisitorPDF", async (params: string) => {
  try {
    const response = await axiosInstance.get(`/visitor-pdf?${params}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const generateToPdf = createAsyncThunk("generateToPdf", async (params: string) => {
  try {
    const { data } = await axiosInstance.get(`/visitor-pdf?${params}`, {
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(data);
    saveAs(url, "report_unk_visitor.pdf");
  } catch (error: any) {
    throw error.response.data;
  }
});
