import jwt_decode from "jwt-decode";

export const filterModules = (listModules: any[], moduleId: string) =>
  listModules.filter((data: any) => data.moduleId === moduleId);

export const filterAccess = (routes: any[]): any[] => {
  let listRoutes: any[] = [];

  const getToken = localStorage.getItem(process.env.REACT_APP_AUTH as string);

  if (getToken === null) return [];

  const splitToken = getToken.split(" ");
  const { listModuleAccess }: any = jwt_decode(splitToken[1]);

  for (const { id, path, element } of routes) {
    for (const { moduleId, canRead } of listModuleAccess) {
      const index = listRoutes.findIndex((prev) => prev.path === path);
      // validate dynamic module
      if (id === moduleId && canRead === 1 && index === -1) {
        listRoutes.push({ id, path, element });
      }

      // validation for allow module
      if (id === "allow" && index === -1) {
        listRoutes.push({ id, path, element });
      }
    }
  }

  return listRoutes;
};
