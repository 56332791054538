import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";
import jwt_decode from "jwt-decode";

interface state {
  isLoading: boolean;
  qrValue: {
    code: string;
    resident: string;
    residentName: string;
  };
  totalGenerated: number;
  totalScanned: number;
}

const initialState: state = {
  isLoading: false,
  qrValue: {
    code: "",
    resident: "",
    residentName: "",
  },
  totalGenerated: 0,
  totalScanned: 0,
};

const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    getDecodeToken: (state, { payload }) => {
      if (payload !== null) {
        const splitToken = payload.split(" ");
        const decodeToken: any = jwt_decode(splitToken[1]);
        const { uuid, username, name, residentId, resident, residentName } = decodeToken;
        state.qrValue = {
          code: residentId ?? uuid,
          resident: resident ?? username,
          residentName: residentName ?? name,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initialDashboard.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(initialDashboard.fulfilled, (state, { payload }) => {
        const { totalBarcode, totalScanned } = payload;
        state.totalGenerated = totalBarcode;
        state.totalScanned = totalScanned;
        state.isLoading = false;
      })
      .addCase(initialDashboard.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const initialDashboard = createAsyncThunk("initialDashboard", async () => {
  try {
    const { data } = await axiosInstance.get("/dashboard");
    return data;
  } catch (err: any) {
    throw err.response.data;
  }
});

export const { getDecodeToken } = slice.actions;
export default slice.reducer;
