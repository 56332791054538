import { MenuItem } from "./index";

export const routePath = {
  // AUTH
  SIGNIN: { id: "", pathName: "/signin" },

  //DASHBOARD
  DASHBOARD: { id: "", pathName: "/dashboard" },

  // Announcement
  ANNOUNCEMENT: { id: "2e07d567-e5c6-4f42-92f6-f515b9281e86", pathName: "/announcement" },

  // Management Team
  ROLES: { id: "e114b4a4-17d8-4df8-b484-c913c93e36d1", pathName: "/management-team/roles" },
  EMPLOYEES: { id: "c9c75d5f-7270-4f3e-b609-78b77bee3ee6", pathName: "/management-team/employees" },
  PERMISSION: {
    id: "cbcfa570-08da-4135-bb73-77e62db23651",
    pathName: "/management-team/permission",
  },
  ROLE_PERMISSION: {
    id: "cbcfa570-08da-4135-bb73-77e62db23651",
    pathName: "/management-team/permission/role-permission/:id",
  },
  SECURITY: {
    id: "a639dc55-8f0f-4bb3-afab-5ecc96f0f541",
    pathName: "/management-team/permission/security",
  },

  // Gate History
  VISITOR: {
    id: "6fa016b2-9cd5-40bc-abe3-b7c6e53ac710",
    pathName: "/management-visitor/visitor",
  },
  HISTORY_VISITOR: {
    id: "e5610969-7b9e-45c1-b165-fefac981c104",
    pathName: "/management-visitor/history",
  },

  SCAN_VISITOR: {
    id: "3852ef22-cccd-4fbd-be19-c9058410634c",
    pathName: "/scan-visitor",
  },

  CHANGE_PASSWORD: { id: "allow", pathName: "/settings/change-password" },

  // 404
  NOT_FOUND: { id: "", pathName: "/not-found" },
};

const menuItems: MenuItem[] = [
  {
    id: "allow",
    name: "Dashboard",
    link: routePath.DASHBOARD.pathName,
  },
  {
    id: routePath.ANNOUNCEMENT.id,
    name: "Announcement",
    link: routePath.ANNOUNCEMENT.pathName,
  },
  {
    id: "39761e43-372e-45a4-87b9-3c4f7380839d",
    name: "Management Team",
    link: "/management-team",
    items: [
      {
        id: routePath.ROLES.id,
        name: "Roles",
        link: routePath.ROLES.pathName,
      },
      {
        id: routePath.EMPLOYEES.id,
        name: "Employees",
        link: routePath.EMPLOYEES.pathName,
      },
      {
        id: routePath.PERMISSION.id,
        name: "Permissions",
        link: routePath.PERMISSION.pathName,
      },
      {
        id: routePath.SECURITY.id,
        name: "Security",
        link: routePath.SECURITY.pathName,
      },
    ],
  },
  {
    id: "84c1d6e1-746c-450b-9524-a36368a53ba5",
    name: "Management Visitor",
    link: "/management-visitor",
    items: [
      {
        id: routePath.VISITOR.id,
        name: "Visitor",
        link: routePath.VISITOR.pathName,
      },
      {
        id: routePath.HISTORY_VISITOR.id,
        name: "History Visitor",
        link: routePath.HISTORY_VISITOR.pathName,
      },
    ],
  },
  {
    id: routePath.SCAN_VISITOR.id,
    name: "Scan Visitor",
    link: routePath.SCAN_VISITOR.pathName,
  },
  {
    id: "allow",
    name: "Settings",
    link: "/settings",
    items: [
      {
        id: routePath.CHANGE_PASSWORD.id,
        name: "Change Password",
        link: routePath.CHANGE_PASSWORD.pathName,
      },
    ],
  },
];

export default menuItems;
